<template>
  <div class="app-container">
    <!-- 查询开始 -->
    <div>
      <!--高级搜索输入框开始 -->
      <div v-if="show">
        <el-form :inline="true" class="demo-form-inline">
          <!-- <el-form-item label="状态">
            <el-select v-model="stateIdValue" placeholder="请选择" clearable disabled>
              <el-option
                v-for="item in options3"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="外部订单号">
            <el-input
              v-model="orderValue"
              placeholder="请输入外部订单号"
              :disabled="disableds"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="流水号">
            <el-input
              v-model="SerialNum"
              placeholder="请输入流水号"
              :disabled="disableds"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="付款方式">
            <el-select v-model="stateValue" placeholder="请选择" clearable>
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收款渠道">
            <el-select v-model="collectionValue" placeholder="请选择" clearable>
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="timeValue"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="timestamp"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div class="searchForm">
        <el-form :inline="true" class="demo-form-inline">
          <!-- <el-form-item label="商户号" v-if="user.level !== 3">
            <el-input
              v-model="seachShopId"
              placeholder="请输入商户号"
              clearable
            ></el-input>
          </el-form-item> -->
          <el-form-item label="交易笔数:">
            <span class="payTotal">{{ totalSize }}</span>
          </el-form-item>
          <el-form-item label="交易金额:">
            <span class="payTotal">{{ totaMoney }}元</span>
          </el-form-item>
          <el-form-item label="支付时间">
            <el-date-picker
              v-model="payTime"
              type="datetimerange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="timestamp"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="车牌号">
            <el-input
              v-model="car_number"
              placeholder="请输入车牌号"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              round
              plain
              @click="search"
              >搜索</el-button
            >
            <el-button
              @click="show = !show"
              plain
              type="primary"
              :icon="show ? 'el-icon-zoom-out' : 'el-icon-zoom-in'"
              round
              style="border: 0"
              >高级搜索</el-button
            >
          </el-form-item>
        </el-form>
        <div>
          <el-button plain type="primary" round @click="exportTable"
 
            >导出</el-button
          >
        </div>
      </div>
    </div>
    <!-- 查询结束 -->
    <!-- 表格开始 -->
    <div>
      <el-table
        border
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        :data="tableDataList"
        style="width: 100%"
        :height="this.show ? 640 : 700"
        :row-class-name="tableRowClassName"
      >
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="服务商">
                <span>{{ scope.row.projectContractorIds }}</span>
              </el-form-item>
              <el-form-item label="支付方式">
                <span>{{ scope.row.payType }}</span>
              </el-form-item>
              <el-form-item label="商品描述">
                <span>{{ scope.row.remark }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column label="序号" width="100">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.num }}</span>
          </template>
        </el-table-column>
        <el-table-column label="商户" width="180">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.shopIds }}</span>
          </template>
        </el-table-column>
        <el-table-column label="车牌号" width="100">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.car_num }}</span>
          </template>
        </el-table-column>
        <el-table-column label="金额(元)">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.total }}</span>
          </template>
        </el-table-column>
        <el-table-column label="手续费(元)">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{
              scope.row.feeTotal ? scope.row.feeTotal : ""
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="支付时间" width="170">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.payTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" width="170">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.createTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="付款渠道">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.payChannel }}</span>
          </template>
        </el-table-column>
        <el-table-column label="收款渠道">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{
              scope.row.settlementChannel
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="流水号" width="180">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.id }}</span>
          </template>
        </el-table-column>
        <el-table-column label="外部订单号" width="160">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.outOrderId }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="80">
          <template slot-scope="scope">
            <el-tag
              :type="
                scope.row.state == 0
                  ? ''
                  : scope.row.state == 1
                  ? 'success'
                  : scope.row.state == 2
                  ? 'warning'
                  : scope.row.state == 3
                  ? 'info'
                  : ''
              "
              size="small"
              >{{ scope.row.states }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" width="70" fixed="right">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="warning"
              plain
              @click="refund(scope.row)"
              >退款</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 删除成功弹窗开始 -->
      <el-dialog
        title="退款"
        top="30vh"
        :visible.sync="dialogVisibleDel"
        width="15%"
      >
        <span style="font-size: 20px">你确定需要退款吗？请继续！</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleDel = false">取 消</el-button>
          <el-button type="primary" @click="SureDel" :disabled="prohibitClick"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <!-- 删除成功弹窗结束 -->
    </div>
    <!-- 表格结束 -->
    <!-- 分页开始 -->
    <div style="text-align: right">
      <Paging :totalSize="totalSize" :getMethods="getOrder" />
    </div>
    <!-- 分页结束 -->
  </div>
</template>

<script>
import Query from "../../components/Query/Query.vue";
import Paging from "../../components/Paging/Paging.vue";
import fileDownload from "js-file-download";
export default {
  components: {
    Query,
    Paging,
  },
  data() {
    return {
      user: {},
      loading: true,
      seachShopId: "", //商户号搜索框
      receivablesShow: true, //支付方式显示控制
      show: false,
      tableDataList: [],

      SerialNum: "", //
      orderValue: "", //外部订单号
      stateValue: "", //付款方式搜索框
      disableds: false, //高级搜索打开时姓名输出框是否禁用(次功能未采用)
      timeValue: null, //订单选中的日期
      payTime: null, //支付时间
      // 日期选择开始
      options: [
        //付款方式搜索框
        {
          value: "1",
          label: "支付宝",
        },
        {
          value: "2",
          label: "微信",
        },
        {
          value: "",
          label: "无",
        },
      ],
      options2: [
        //收款渠道
        {
          value: "1",
          label: "支付宝",
        },
        {
          value: "2",
          label: "微信",
        },
        {
          value: "3",
          label: "云纵",
        },
        {
          value: "4",
          label: "昆山农商行",
        },
        {
          value: "",
          label: "无",
        },
      ],
      options3: [
        //状态搜索栏
        {
          value: 1,
          label: "已付",
        },
        {
          value: 0,
          label: "未付",
        },
        {
          value: 2,
          label: "退款",
        },
        {
          value: 3,
          label: "已关闭",
        },
      ],
      stateIdValue:1, //状态搜索框value值
      collectionValue: "", //收款渠道搜索框value值

      totalSize: 0, // 总条数
      totaMoney: "0", //总金额

      shopTbMap: {},
      dialogVisibleDel: false, //退款弹框
      refundData: {}, //点击退款数据

      alert: "", //提示信息
      pageDatalist: {},
      prohibitClick: false, //退款禁止按钮功能
      car_number: "",
    };
  },
  created() {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    const timeStart = new Date(new Date().toLocaleDateString()).getTime();
    let timeEnd =
      new Date(new Date().toLocaleDateString()).getTime() +
      24 * 60 * 60 * 1000 -
      1;
    this.payTime = [timeStart, timeEnd];
  },
  methods: {
    //公共参数
    queryParams(page) {
      // 时间转换
      let datalists = {
        pageConfig: {
          orderType: "desc",
          pageSize: page.pageSize,
          orderField: "id",
          pageNum: page.pageNum
        },
        // 传入数据
        baseBean: {
          payChannel: this.stateValue,
          id: this.SerialNum.trim(),
          settlementChannel: this.collectionValue,
          state: this.stateIdValue,
          shopId: this.seachShopId,
        },
        searchBeans: [],
      };
      // 外部订单号
      if (this.orderValue) {
        datalists.searchBeans = [
          ...datalists.searchBeans,
          {
            fieldName: "outOrderId", //字段
            basicValue: this.orderValue,
            operator: "LIKE", //模糊
          },
        ];
      }
      //创建时间
      if (this.timeValue) {
        datalists.searchBeans = [
          ...datalists.searchBeans,
          {
            fieldName: "createTime", //字段
            startValue: this.timeValue[0] / 1000, //值
            endValue: this.timeValue[1] / 1000,
            operator: "BETWEEN", //大于等于
          },
        ];
      }
      // 支付时间
      if (this.payTime !== null) {
        datalists.searchBeans = [
          ...datalists.searchBeans,
          {
            fieldName: "payTime", //字段
            startValue: this.payTime[0], //值
            endValue: this.payTime[1],
            operator: "BETWEEN", //大于等于
          },
        ];
      }
      // 车牌
      if (this.car_number) {
        datalists.searchBeans = [
          ...datalists.searchBeans,
          {
            fieldName: "extension", //字段
            basicValue: this.car_number.trim(),
            operator: "LIKE", //大于等于
          },
        ];
      }
      return datalists;
    },
    // 获取订单
    getOrder(pageData) {
      this.loading = true;
      this.pageDatalist = { ...pageData };
      let datalists = this.queryParams(this.pageDatalist);
      this.$axios.post("/orderTrade/queryTrade", datalists).then((res) => {
        this.loading = false;
        const ret = res.data;
        this.totalSize = ret.count;
        this.totaMoney = ret.total ? ret.total / 100 : 0;
        let datalist = ret.orderTradePage || []; //返回数据
        this.shopTbMap = ret.shopTbMap;
        let number =
          (this.pageDatalist.pageNum - 1) * this.pageDatalist.pageSize; //分页时编号计算
        datalist.map((item) => {
          item.total = item.total / 100;
          number++;
          item.num = number;
          item.shopIds = ret.shopTbMap[item.shopId];
          item.projectContractorIds =
            ret.projectTbMap[item.projectContractorId];
          if (item.createTime !== undefined) {
            item.createTime = this.getTiem(item.createTime);
          }
          if (item.payTime !== undefined) {
            item.payTime = this.getTiem(item.payTime / 1000);
          }
          if (item.state === 0) {
            item.states = "未支付";
          } else if (item.state === 1) {
            item.states = "已支付";
          } else if (item.state === 2) {
            item.states = "已退款";
          } else if (item.state === 3) {
            item.states = "已关闭";
          }
          if (item.payType === 1) {
            item.payType = "主扫";
          } else {
            item.payType = "被扫";
          }
          if (item.payChannel === 1) {
            item.payChannel = "支付宝";
          } else if (item.payChannel === 2) {
            item.payChannel = "微信";
          }
          if (item.settlementChannel === 1) {
            item.settlementChannel = "支付宝账户";
          } else if (item.settlementChannel === 2) {
            item.settlementChannel = "微信账户";
          } else if (item.settlementChannel === 3) {
            item.settlementChannel = "云纵";
          } else if (item.settlementChannel === 4) {
            item.settlementChannel = "昆山农商行";
          }
          if (item.extension && item.extension.indexOf("{") !== -1) {
            item.car_num = JSON.parse(item.extension).car_number;
          }
          return item;
        });
        this.tableDataList = datalist;
      });
    },
    // 搜索
    search() {
      this.getOrder(this.pageDatalist);
    },
    // 退款
    refund(row) {
      this.prohibitClick = false;
      this.refundData = row;
      this.dialogVisibleDel = true;
    },

    // 确认退款
    SureDel() {
      this.prohibitClick = true; //点击确定退款后，禁止退款功能按钮，5秒后才能点击
      setTimeout(() => {
        this.prohibitClick = false;
      }, 5000);
      const { shopId, orderId, projectContractorId, id } = this.refundData;
      this.$axios
        .get("/orderTrade/tradeRefund", {
          params: {
            tradeId: id,
            orderId,
            shopId,
            projectId: projectContractorId,
          },
        })
        .then((res) => {
          if (res.data.state === 1) {
            this.alert =
              res.data.msg + " " + "， " + " " + res.data.desc + "!" + ""; //返回的提示信息
            this.DeleteSuccess();
            this.getOrder(this.pageDatalist);
            this.dialogVisibleDel = false;
          } else if (res.data.state === 0) {
            this.alert =
              res.data.msg + " " + "， " + " " + res.data.desc + "!" + ""; //返回的提示信息
            this.DeleteFailure();
            this.dialogVisibleDel = false;
          }
        });
    },

    //导出
    exportTable() {
      if (this.stateIdValue === 1) {
         const loading = this.$loading({
          lock: true,
          text: '正在导出,请稍后',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let datalists = this.queryParams(this.pageDatalist);
        this.$axios
          .post("/orderTrade/exportTrade", datalists, { responseType: "blob" })
          .then((res) => {
            loading.close();
            const ret = res.data;
            if (ret.type === "application/octet-stream") {
              fileDownload(ret, "支付流水.xlsx");
              return;
            }
            const reader = new FileReader();
            reader.readAsText(ret, "utf-8");
            reader.onload = () => {
              let data = JSON.parse(reader.result);
              this.$message({ message: data.msg, type: "error" });
            };
          });
        return;
      }
      this.$message({ message: "只能导出已付订单", type: "error" });
    },
    //操作成功提示
    DeleteSuccess() {
      this.$message({
        message: `${this.alert}`,
        type: "success",
      });
    },
    //操作失败提示
    DeleteFailure() {
      this.$message.error(`${this.alert}`);
    },
    // 时间转换
    getTiem(params) {
      let unixTimestamp = new Date(params * 1000); //时间戳转换成日期
      // 定义转换返回方式
      Date.prototype.toLocaleString = function () {
        return (
          this.getFullYear() +
          "-" +
          (this.getMonth() + 1) +
          "-" +
          +this.getDate() +
          " " +
          this.getHours() +
          ":" +
          this.getMinutes() +
          ":" +
          this.getSeconds()
        );
      };
      params = unixTimestamp.toLocaleString();
      return params;
    },
    // 表格颜色
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "success-row";
      }
      return "";
    },
  },
};
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.pag {
  margin-top: 10px;
  text-align: right;
  padding-right: 50px;
}
#pag {
  position: fixed;
  bottom: 40px;
  right: 10px;
}
.el-table .cell {
  text-align: center;
}
.el-col-4 {
  width: 13.66667%;
}

.el-table td {
  padding: 10px 0;
}
.payTotal {
  font-size: 20px;
  color: #0e8bfa;
  margin-right: 10px;
}
.searchForm {
  display: flex;
  justify-content: space-between;
}
</style>